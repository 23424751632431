.intro-title strong {
  font-weight: 800;
  color: #59355D;
   /* font-size: 4rem; */
  /* line-height: 7.1rem; */ 
}

.intro-desc{
  font-size: 1.8rem;
    line-height: 3.1rem;
    font-weight: 300;
    /* max-width: 50rem; */
    margin-bottom: 3rem;
    color: #0B0B0B;
}
.logo{
  max-width: 13rem;
  height:auto;
}
.app-logo{
  max-width: 135px;
  height: auto;
}

*{
  font-family: 'Manrope',Helvetica,Arial,Lucida,sans-serif;
}
a{
  text-decoration: none;
}
.home{
  background: linear-gradient(180deg,rgba(242,244,255,0.8) 0%,rgba(255,242,242,0.8) 0.01%,rgba(239,237,255,0) 100%);
}
.audio{
  width:50%;
  margin: auto;
}
@media screen and (max-width: 599px){
  .logo{
    max-width: 10rem;
    height:auto;
  }
}